import { Button, Col, Row } from "antd";
import React from "react";
import logo from "../../img/logo-neuro-color.svg";
import "../BannerHeader/Style/StyleHeaderBanner.css";
import { Animated } from "react-animated-css";
import swal from "@sweetalert/with-react";

function HeaderBanner() {
  return (
    <>
      <div className="div-banner-header">
        <span className="circle-background"></span>
        <span className="rectangle-background"></span>
        <span className="rectangle-background-mobile"></span>
        <Row className="row-logo">
          <Col
            span={12}
            className="col-header-logo"
            xs={0}
            sm={0}
            md={0}
            lg={12}
          >
            <Animated animationIn="fadeIn" animationInDelay={1200}>
              <img
                className="logo-Banner"
                src={logo}
                alt="Logo Carla Guzmán Leiva Psicologa Neuropsicología en antofagasta"
              ></img>
            </Animated>
          </Col>
          <Col
            span={12}
            className="col-header-titulo"
            xs={0}
            sm={0}
            md={0}
            lg={12}
          >
            <Animated animationIn="slideInLeft" animationInDelay={1000}>
              <h1 className="titulo-header">Carla Guzmán Leiva</h1>
            </Animated>
            <Animated animationIn="slideInLeft" animationInDelay={1000}>
              <h2 className="subtitulo-header">Psicóloga Clínica</h2>
            </Animated>
            <Animated animationIn="slideInLeft" animationInDelay={1000}>
              <h2 className="subtitulo-header">Máster en Neuropsicología</h2>
            </Animated>

            <Animated animationIn="fadeIn" animationInDelay={1200}>
              <button
                className="boton-contacto"
                type="primary"
                shape="round"
                size="large"
                onClick={() =>
                  swal({
                    buttons: {
                      cancel: "Cerrar"
                    },
                    content: (
                      <div>
                        <form
                          action="https://public.herotofu.com/v1/13b591d0-1854-11ee-a6b0-17653bd30bd3"
                          method="POST"
                          className="card-form"
                          target="_blank"
                        >
                          <div className="input">
                            <input
                              type="text"
                              name="Nombre Completo"
                              className="input-field"
                              required
                            />
                            <label className="input-label">
                              Nombre Completo
                            </label>
                          </div>
                          <div className="input">
                            <input
                              type="text"
                              name="Email"
                              className="input-field"
                              required
                            />
                            <label className="input-label">Email</label>
                          </div>
                          <div className="input">
                            <input
                              className="input-field"
                              name="Teléfono"
                              required
                            />
                            <label className="input-label">
                              Número de teléfono
                            </label>
                          </div>
                          <div className="action">
                            <button className="action-button">Enviar</button>
                          </div>
                        </form>
                      </div>
                    )
                  })
                }
              >
                Contacto
              </button>
            </Animated>
          </Col>
          <Col
            span={12}
            className="col-header-titulo"
            xs={24}
            sm={24}
            md={24}
            lg={0}
          >
            <Animated animationIn="slideInLeft" animationInDelay={1000}>
              <h1 className="titulo-header-mobile">Carla Guzmán Leiva</h1>
            </Animated>
            <Animated animationIn="slideInLeft" animationInDelay={1000}>
              <h2 className="subtitulo-header-mobile">Psicóloga Clínica</h2>
            </Animated>
            <Animated animationIn="slideInLeft" animationInDelay={1000}>
              <h2 className="subtitulo-header-mobile">Máster en Neuropsicología</h2>
            </Animated>

            <Animated animationIn="fadeIn" animationInDelay={1200}>
              <button
                className="boton-contacto-mobile"
                type="primary"
                shape="round"
                size="large"
                onClick={() =>
                  swal({
                    buttons: {
                      cancel: "Cerrar"
                    },
                    content: (
                      <div>
                        <form
                          action="https://public.herotofu.com/v1/13b591d0-1854-11ee-a6b0-17653bd30bd3"
                          method="POST"
                          className="card-form"
                          target="_blank"
                        >
                          <div className="input">
                            <input
                              type="text"
                              name="Nombre Completo"
                              className="input-field"
                              required
                            />
                            <label className="input-label">
                              Nombre Completo
                            </label>
                          </div>
                          <div className="input">
                            <input
                              type="text"
                              name="Email"
                              className="input-field"
                              required
                            />
                            <label className="input-label">Email</label>
                          </div>
                          <div className="input">
                            <input
                              className="input-field"
                              name="Teléfono"
                              required
                            />
                            <label className="input-label">
                              Número de teléfono
                            </label>
                          </div>
                          <div className="action">
                            <button className="action-button">Enviar</button>
                          </div>
                        </form>
                      </div>
                    )
                  })
                }
              >
                Contacto
              </button>
            </Animated>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HeaderBanner;
