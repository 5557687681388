import { Col, Row } from "antd";
import React from "react";
import "../Footer/Style/FooterStyle.css";
import logo from "../../img/logo-neuro-color.svg"

function Footer() {
  return (
    <>
      <div className="footer-holder">
        <Row justify={"space-between"} className="row-link-foot" align={"middle"}>
          <Col className="links-col">
            <p className="link-foot">Carla Guzmán Leiva</p>
            <p className="link-foot">Neuropsicóloga</p>
            <p className="link-foot">Atención en Antofagasta</p>
            <p>
              <a href="tel:+56934506527" className="link-foot">+56 9 3450 6527</a>
            </p>
            <p>
              <a href="mailto:reservas@cglneuropsicologia.cl?Subject=Contacto%20Neuropsicología" className="link-foot">
              reservas@cglneuropsicologia.cl
              </a>
            </p>
          </Col>
          <Col>
            <img src={logo} alt="neuropsicologo en antofagasta, carla guzman leiva" className="logo-foot"/>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <h3 className="copy-foot">Carla Guzmán Leiva - Copyright © 2023 Neuropsicología Antofagasta, Chile.</h3>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Footer;
