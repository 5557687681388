import { Col, Popover, Row } from "antd";
import React from "react";
import "../AmbitosDeAplicacion/Style/AmbitosStyle.css";
import brainLogo from "../../img/brainIcon.png";
import trasneu from "../../img/trasNeu.png";
import brainles from "../../img/brainLesion.png";
import difCog from "../../img/difCog.png";
import trasNeur from "../../img/trasNeur.png";
import condNeu from "../../img/condNeu.png";

function AmbitosDeAplicacion() {
  const contentTRNR = (
    <div className="content-sty">
      <ul>
        <li>Enfermedad de Alzheimer</li>
        <br />
        <li>Esclerosis lateral Amiotrófica</li>
        <br />
        <li>Enfermedad de Huntington</li>
        <br />
        <li>Demencia por cuerpos de Lewy</li>
        <br />
        <li>Enfermedad de Parkinson</li>
      </ul>
    </div>
  );
  const contentLESAD = (
    <div className="content-sty">
      <ul>
        <li>Traumatismo Craneoencefálico</li>
        <br />
        <li>Accidente Cerebro Vascular</li>
        <br />
        <li>Hipoxia/Anoxia</li>
      </ul>
    </div>
  );

  const contentDIFCOG = (
    <div className="content-sty">
      <ul>
        <li>Trastorno del estado de Ánimo</li>
        <br />
        <li>Trastorno de la Conducta</li>
        <br />
        <li>Trastorno Psiquiátrico</li>
      </ul>
    </div>
  );
  const contentTRNEU = (
    <div className="content-sty">
      <ul>
        <li>Discapacidad Intelectual</li>
        <br />
        <li>Trastorno del Espectro Autista</li>
        <br />
        <li>Trastorno por Déficit Atencional</li>
        <br />
        <li>Trastorno de Aprendizaje</li>
        <br />
        <li>Trastorno del Desarrollo del Lenguaje</li>
        <br />
        <li>Trastornos Motores</li>
        <br />
        <li>Entre otros</li>
      </ul>
    </div>
  );
  const contentCONDMED = (
    <div className="content-sty">
      <ul>
        <li>
          Síndromes genéticos asociados <br /> a dificultades
          Cognitivas-Neurológicas
        </li>
        <br />
        <li>Epilepsia</li>
        <br />
        <li>Tumores Cerebrales</li>
        <br />
        <li>Infecciones</li>
        <br />
        <li>Meningitis</li>
        <br />
        <li>Encefalitis</li>
      </ul>
    </div>
  );

  return (
    <>
      <div className="AmbitosHolder">
        <span className="ball-1" />
        <span className="ball-2" />
        <span className="ball-3" />
        <span className="ball-4" />
        <span className="ball-5" />
        <Row justify={"space-evenly"}>
          <Col align={"center"}>
            <h1 className="am-tit">Ámbitos de aplicación</h1>
          </Col>
        </Row>
        <Row justify={"space-evenly"} className="first-row">
          <Col>
            <Popover placement="bottom" content={contentTRNR}>
              <button className="aa-holder">
                <img
                  src={trasneu}
                  alt="icono de neurona para trastornos neurodegenerativos"
                  className="icon"
                />
                <h2 className="tit-tr-nd">
                  Trastornos
                  <br />
                  Neurodegenerativos
                </h2>
              </button>
            </Popover>
          </Col>
          <Col>
            <Popover placement="bottom" content={contentLESAD}>
              <button className="aa-holder">
                <img src={brainles} alt="" className="icon" />
                <h2 className="tit-tr-nd">
                  Lesión Cerebral
                  <br />
                  Adquirida
                </h2>
              </button>
            </Popover>
          </Col>
        </Row>
        <Row align={"middle"} justify={"space-evenly"}>
          <Col>
            <Popover placement="right" content={contentDIFCOG}>
              <button className="aa-holder">
                <img src={difCog} alt="" className="icon" />
                <h2 className="tit-tr-nd">
                  Dificultades Cognitivas
                  <br />
                  por trastornos Psiquiátricos
                </h2>
              </button>
            </Popover>
          </Col>
          <Col xs={0} sm={0} md={0} lg={5}>
            <img src={brainLogo} alt="" className="bk-brain" />
          </Col>
          <Col>
            <Popover placement="left" content={contentTRNEU}>
              <button className="aa-holder">
                <img
                  src={trasNeur}
                  alt="icono de neurona para trastornos neurodegenerativos"
                  className="icon"
                />
                <h2 className="tit-tr-nd">
                  Trastornos del
                  <br />
                  Neurodesarrollo
                </h2>
              </button>
            </Popover>
          </Col>
        </Row>
        <Row justify={"center"} className="last-row">
          <Col>
            <Popover placement="top" content={contentCONDMED}>
              <button className="aa-holder">
                <img
                  src={condNeu}
                  alt="icono de neurona para trastornos neurodegenerativos"
                  className="icon"
                />
                <h2 className="tit-tr-nd">
                  Condiciones Médicas
                  <br />
                  Neurológicas
                </h2>
              </button>
            </Popover>
          </Col>
        </Row>
      </div>

      {/* div con la version mobil de los ambitos de aplcacion */}
      <div className="ambitos-holder-mobile">
        <Row justify={"space-evenly"} align={"middle"}>
          <Col align={"center"}>
            <h1 className="am-tit">Ámbitos de aplicación</h1>
          </Col>
        </Row>
        <Row justify={"space-evenly"} className="first-row" gutter={[0, 45]}>
          <Col>
            <Popover placement="bottom" content={contentTRNR}>
              <button className="aa-holder">
                <img
                  src={trasneu}
                  alt="icono de neurona para trastornos neurodegenerativos"
                  className="icon"
                />
                <h2 className="tit-tr-nd">
                  Trastornos
                  <br />
                  Neurodegenerativos
                </h2>
              </button>
            </Popover>
          </Col>
          <Col>
            <Popover placement="bottom" content={contentLESAD}>
              <button className="aa-holder">
                <img src={brainles} alt="" className="icon" />
                <h2 className="tit-tr-nd">
                  Lesión Cerebral
                  <br />
                  Adquirida
                </h2>
              </button>
            </Popover>
          </Col>
        </Row>
        <Row align={"middle"} justify={"space-evenly"} gutter={[0, 45]}>
          <Col>
            <Popover placement="bottom" content={contentDIFCOG}>
              <button className="aa-holder">
                <img src={difCog} alt="" className="icon" />
                <h2 className="tit-tr-nd">
                  Dificultades Cognitivas
                  <br />
                  por trastornos Psiquiátricos
                </h2>
              </button>
            </Popover>
          </Col>
          <Col xs={0} sm={0} md={0} lg={24}>
            <img src={brainLogo} alt="" className="bk-brain" />
          </Col>
          <Col>
            <Popover placement="bottom" content={contentTRNEU}>
              <button className="aa-holder">
                <img
                  src={trasNeur}
                  alt="icono de neurona para trastornos neurodegenerativos"
                  className="icon"
                />
                <h2 className="tit-tr-nd">
                  Trastornos del
                  <br />
                  Neurodesarrollo
                </h2>
              </button>
            </Popover>
          </Col>
        </Row>
        <Row justify={"center"} className="last-row">
          <Col>
            <Popover placement="bottom" content={contentCONDMED}>
              <button className="aa-holder">
                <img
                  src={condNeu}
                  alt="icono de neurona para trastornos neurodegenerativos"
                  className="icon"
                />
                <h2 className="tit-tr-nd">
                  Condiciones Médicas
                  <br />
                  Neurológicas
                </h2>
              </button>
            </Popover>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AmbitosDeAplicacion;
