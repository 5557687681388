import { Col, Row } from "antd";
import "../Servicios/Style/StyleServicios.css";
import swal from "sweetalert";
import React, { useState } from "react";
import enpic from "../../img/en.jpg";
import rnpic from "../../img/rn.jpg";

function Servicios() {
  return (
    <>
      <div className="div-banner-servicios">
        <span className="circle-background-sv" />
        <span className="rectangle-background-sv" />
        <Row justify={"center"} className="row-tit">
          <Col className="col-tit">
            <span className="titulo-servicios">Servicios</span>
          </Col>
        </Row>
        <Row
          className="pil-ev-neuro"
          align="middle"
          justify="space-evenly"
          onClick={() =>
            swal({
              className: "swal-pil-ev-neuro",
              title: "Evaluación Neuropsicológica",
              text: "La evaluación Neuropsicológica es un proceso que se realiza con la finalidad de conocer el estado cognitivo, afectivo y conductual de una persona. \n\nPara ello se utilizan distintos instrumentos entre los que se encuentran entrevistas, escalas y cuestionarios, con el objetivo de explorar las funciones y capacidades cognitivas como son atención, memoria, gnosias, lenguaje, praxias o funciones ejecutivas, entre otras. \n\nTambién examina el nivel de autonomía de la persona y la valoración de cambios conductuales, de personalidad o afectivos. \n\nSe realiza en sujetos que han sufrido algún daño neurológico, así como en aquellas personas que padecen un trastorno o patología psiquiátrica o alguna otra enfermedad que repercute a las funciones cognitivas.",
              button: "Cerrar"
            })
          }
        >
          <Col span={6} xs={0} sm={0} md={0} lg={6}>
            <img src={enpic} alt="imagen" className="placeholder" />
          </Col>
          <Col span={14} className="tit-pil">
            <span>
              Evaluación
              <br />
              Neuropsicológica
            </span>
          </Col>
        </Row>
        <Row
          className="pil-re-neuro"
          align="middle"
          justify="space-evenly"
          onClick={() =>
            swal({
              className: "swal-pil-re-neuro",
              title: "Rehabilitación neuropsicológica",
              text: '"la Rehabilitación Neuropsicológica implica la restauración de pacientes al más alto nivel posible de adaptación física, psicológica y social. Incluyendo todas las medidas dirigidas a reducir el impacto de la incapacidad y la condición de desventaja y esfuerzo por lograr que las personas alcancen una interacción social óptima". (OMS, 1996). \n\nSe centra en el tratamiento de las alteraciones que afectan a cualquier dominio cognitivo: atención, memoria, lenguaje, procesos perceptivo-motores, funciones ejecutivas (planificación, inhibición, memoria de trabajo, etc.), conducta y emoción, por ende, la Rehabilitación Neuropsicológica, engloba un campo de estudio y actuación muy amplio del que forman parte diferentes estrategias y métodos de intervención terapéutica cuyo fin es lograr el nivel óptimo de integración social del paciente.',
              button: "Cerrar"
            })
          }
        >
          <Col span={14} className="tit-pil-re">
            <span>
              Rehabilitación
              <br />
              Neuropsicológica
            </span>
          </Col>
          <Col span={6} xs={0} sm={0} md={0} lg={6}>
            <img
              src={rnpic}
              alt="imagen de ejercicios de rehabilitación Neuropsicológica"
              className="placeholder"
            />
          </Col>
        </Row>
        <div className="separator" />
      </div>

      <div className="div-banner-servicios-mobile">
        <Row justify={"center"} align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={0} className="tit-serv-mobile" align={"center"}>
            <span>Servicios</span>
          </Col>
        </Row>
        <div className="separator" />
        <Row
          justify={"center"}
          align={"middle"}
          className="mobile-pil-ev"
          onClick={() =>
            swal({
              className: "swal-pil-ev-neuro",
              title: "Evaluación Neuropsicológica",
              text: "La evaluación Neuropsicológica es un proceso que se realiza con la finalidad de conocer el estado cognitivo, afectivo y conductual de una persona. \n\nPara ello se utilizan distintos instrumentos entre los que se encuentran entrevistas, escalas y cuestionarios, con el objetivo de explorar las funciones y capacidades cognitivas como son atención, memoria, gnosias, lenguaje, praxias o funciones ejecutivas, entre otras. \n\nTambién examina el nivel de autonomía de la persona y la valoración de cambios conductuales, de personalidad o afectivos. \n\nSe realiza en sujetos que han sufrido algún daño neurológico, así como en aquellas personas que padecen un trastorno o patología psiquiátrica o alguna otra enfermedad que repercute a las funciones cognitivas.",
              button: "Cerrar"
            })
          }
        >
          <Col xs={24} sm={24} md={24} lg={0} className="tit-ev-mobile" align={"center"}>
            <span>Evaluaciones<br/>Neuropsicológicas</span>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row
          align={"middle"}
          justify={"center"}
          className="mobile-pil-re"
          onClick={() =>
            swal({
              className: "swal-pil-re-neuro",
              title: "Rehabilitación neuropsicológica",
              text: '"la Rehabilitación Neuropsicológica implica la restauración de pacientes al más alto nivel posible de adaptación física, psicológica y social. Incluyendo todas las medidas dirigidas a reducir el impacto de la incapacidad y la condición de desventaja y esfuerzo por lograr que las personas alcancen una interacción social óptima". (OMS, 1996). \n\nSe centra en el tratamiento de las alteraciones que afectan a cualquier dominio cognitivo: atención, memoria, lenguaje, procesos perceptivo-motores, funciones ejecutivas (planificación, inhibición, memoria de trabajo, etc.), conducta y emoción, por ende, la Rehabilitación Neuropsicológica, engloba un campo de estudio y actuación muy amplio del que forman parte diferentes estrategias y métodos de intervención terapéutica cuyo fin es lograr el nivel óptimo de integración social del paciente.',
              button: "Cerrar"
            })
          }
        >
          <Col xs={24} sm={24} md={24} lg={0} className="tit-re-mobile" align={"center"}>
            <span>Rehabilitación<br/>Neuropsicológica</span>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Servicios;
