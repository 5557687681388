import HeaderBanner from "./views/BannerHeader/HeaderBanner";
import { Affix, Button, Col, Drawer, Row } from "antd";
import Servicios from "./views/Servicios/Servicios";
import "../src/Style/AppStyle.css";
import AmbitosDeAplicacion from "./views/AmbitosDeAplicacion/AmbitosDeAplicacion";
import Contacto from "./views/Contacto/Contacto";
import Footer from "./views/Footer/Footer";
import "../src/views/NavBar/style/StyleNavbar.css";
import ThanksPage from "./views/Thanks/ThanksPage";
import logo from "../src/img/logo-neuro-color.svg";
import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";

function App() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Affix>
        <Row className="navbar-container" justify={"center"}>
          <Col flex={2} xs={0} sm={0} md={0} lg={2}>
            <a href="#inicio">
              <img
                className="logo-navbar"
                src={logo}
                alt="Logo Carla Guzmán Leiva Psicologa Neuropsicología"
              />
            </a>
          </Col>
          <Col flex={2} xs={0} sm={0} md={0} lg={2}></Col>
          <Col
            flex={1}
            align="right"
            className="contenedor-link"
            xs={0}
            sm={0}
            md={0}
            lg={20}
          >
            <a
              className="link-navbar"
              href="#inicio"
              onClick={() => {
                document.getElementById("part-1").scrollIntoView();
              }}
            >
              Inicio
            </a>
            <a
              className="link-navbar"
              href="#servicios"
              onClick={() => {
                document.getElementById("part-2").scrollIntoView();
              }}
            >
              Servicios
            </a>
            <a
              className="link-navbar"
              href="#ambitos-de-aplicacion"
              onClick={() => {
                document.getElementById("part-3").scrollIntoView();
              }}
            >
              Ámbitos de Aplicación
            </a>
            <a
              className="link-navbar"
              href="#contacto"
              onClick={() => {
                document.getElementById("part-4").scrollIntoView();
              }}
            >
              Contacto
            </a>
          </Col>
        </Row>

        <Row>
          <Col flex={2} xs={2} sm={2} md={2} lg={0}>
            <a href="#">
              <img
                className="logo-navbar"
                src={logo}
                alt="Logo Carla Guzmán Leiva Psicologa Neuropsicología"
              />
            </a>
          </Col>
          <Col flex={2} xs={2} sm={2} md={2} lg={0}></Col>
          <Col
            flex={1}
            align="right"
            className="contenedor-link"
            xs={20}
            sm={20}
            md={20}
            lg={0}
          >
            <Button type="primary" onClick={showDrawer}>
              <MenuOutlined className="icon-menu" />
            </Button>
            <Drawer
              title="Menú de Navegación"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <p>
                <a
                  className="link-navbar"
                  href="#inicio"
                  onClick={() => {
                    document.getElementById("part-1").scrollIntoView();
                    onClose();
                  }}
                >
                  Inicio
                </a>
              </p>
              <p>
                <a
                  className="link-navbar"
                  href="#servicios"
                  onClick={() => {
                    document.getElementById("part-2").scrollIntoView();
                    onClose();
                  }}
                >
                  Servicios
                </a>
              </p>
              <p>
                <a
                  className="link-navbar"
                  href="#ambitos-de-aplicacion"
                  onClick={() => {
                    document.getElementById("part-3").scrollIntoView();
                    onClose();
                  }}
                >
                  Ámbitos de Aplicación
                </a>
              </p>
              <p>
                <a
                  className="link-navbar"
                  href="#contacto"
                  onClick={() => {
                    document.getElementById("part-4").scrollIntoView();
                    onClose();
                  }}
                >
                  Contacto
                </a>
              </p>
            </Drawer>
          </Col>
        </Row>
      </Affix>

      <div id="part-1">
        <HeaderBanner />
      </div>
      <Row>
        <Col span={24} className="page-spacer" />
      </Row>
      <div id="part-2">
        <Servicios />
      </div>
      <Row>
        <Col span={24} className="page-spacer" />
      </Row>
      <div id="part-3">
        <AmbitosDeAplicacion />
      </div>
      <Row>
        <Col span={24} className="page-spacer" />
      </Row>
      <div id="part-4">
        <Contacto />
      </div>
      <Row>
        <Col span={24} className="page-spacer" />
      </Row>
      <Footer />
      {/* <ThanksPage/> */}
    </>
  );
}

export default App;
