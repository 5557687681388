import { Col, Row } from "antd";
import React from "react";
import logo from "../../img/logo-neuro-color.svg";
import bkcont from "../../img/logo-neuro-color.svg";
import "../Contacto/Style/ContactoStyle.css";

function Contacto() {
  return (
    <>
      <div className="contact-holder">
        <img src={bkcont} alt="" className="cont-bk" />
        <Row justify={"space-evenly"}>
          <Col>
            <img src={logo} alt="" className="logo-cont" />
            <br />
            <span className="nomb-cont">Carla Guzmán Leiva</span>
            <br />
            <br />
            <span className="datos-cont">Psicóloga Clínica</span>
            <br />
            <br />
            <span className="datos-cont">Máster en Neuropsicología</span>
            <br />
            <br />
            <span className="datos-cont">Teléfono: +56 9 3450 6527</span>
            <br />
            <br />
            <span className="datos-cont">
              Correo: reservas@cglneuropsicologia.cl
            </span>
          </Col>
          <Col className="cont-form-col" xs={0} sm={0} md={0} lg={8}>
            <span className="cont-tit">CONTACTO</span>
            <br />
            <br />
            <br />
            <span className="form-tit">Ingrese sus datos a continuación:</span>
            <br />
            <br />
            <div className="card">
              <form
                action="https://public.herotofu.com/v1/13b591d0-1854-11ee-a6b0-17653bd30bd3"
                method="POST"
                className="card-form"
                target="_blank"
              >
                <div className="input">
                  <input
                    type="text"
                    name="Nombre Completo"
                    className="input-field"
                    required
                  />
                  <label className="input-label">Nombre Completo</label>
                </div>
                <div className="input">
                  <input
                    type="text"
                    name="Email"
                    className="input-field"
                    required
                  />
                  <label className="input-label">Email</label>
                </div>
                <div className="input">
                  <input className="input-field" name="Teléfono" required />
                  <label className="input-label">Número de teléfono</label>
                </div>
                <div className="action">
                  <button className="action-button">Enviar</button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>

      <div className="contact-mobile-holder">
        <Row justify={"space-evenly"} align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={0} align={"middle"}>
            <img src={logo} alt="" className="logo-cont-mobile" />
            <br />
            <br />
            <br />
            <span className="nomb-cont-mobile">Carla Guzmán Leiva</span>
          </Col>
          <Col align={"left"} className="mobile-col">
            <div className="datos-col-mobile">
              <br />
              <br />
              <span className="datos-cont-mobile">Psicóloga Clínica</span>
              <br />
              <br />
              <span className="datos-cont-mobile">
                Máster en Neuropsicología
              </span>
              <br />
              <br />
              <span className="datos-cont-mobile">
                Teléfono: +56 9 3450 6527
              </span>
              <br />
              <br />
              <span className="datos-cont-mobile">
                Correo: reservas@cglneuropsicologia.cl
              </span>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row justify={"space-evenly"} align={"middle"}>
          <Col align={"center"}>
            <span className="contact-mobile-tit">Contacto</span>
          </Col>
        </Row>

        <Row justify={"space-evenly"} align={"middle"}>
          <Col>
            <br />
            <span className="form-tit">Ingrese sus datos a continuación:</span>
          </Col>
          <Col align={"center"}>
            <div className="card-mobile">
              <form
                action="https://public.herotofu.com/v1/13b591d0-1854-11ee-a6b0-17653bd30bd3"
                method="POST"
                className="card-form"
                target="_blank"
              >
                <div className="input">
                  <input
                    type="text"
                    name="Nombre Completo"
                    className="input-field"
                    required
                  />
                  <label className="input-label">Nombre Completo</label>
                </div>
                <div className="input">
                  <input
                    type="text"
                    name="Email"
                    className="input-field"
                    required
                  />
                  <label className="input-label">Email</label>
                </div>
                <div className="input">
                  <input className="input-field" name="Teléfono" required />
                  <label className="input-label">Número de teléfono</label>
                </div>
                <div className="action">
                  <button className="action-button">Enviar</button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Contacto;
